$yellow: #fff55d;
$pink: #ec8c94;
$dark-pink: #ff589a;

.Home {
  padding-left: 3rem;
  padding-right: 3rem;

  @media only screen and (max-width: 736px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .welcome-container {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

  .welcome-title {
    background-image: linear-gradient($yellow, $yellow);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 100% 2px;
    transition: background-size 0.5s, background-position 0.3s ease-in 0.5s;
    font-weight: bold;
    margin-bottom: 10px;

    &:hover {
      transition: background-position 0.5s, background-size 0.3s ease-in 0.5s;
      background-size: 100% 100%;
      background-position: 0% 0%;
    }

    @include from($tablet) {
      width: 50%;
    }
  }

  .ftl-title-text {
    color: $dark-pink;
    font-weight: bold;
  }

  .justify-text {
    text-align: justify;
  }

  .teaser-image {
    padding-left: 5%;
    float: right;
    width: 40%;
  }

  .button {
    margin: 15px 15px 0px 0px;
  }

  .centerImg {
    text-align: center;
  }

  @include until($tablet) {
    .reverse-columns {
      flex-direction: column-reverse;
      display: flex;
    }
  }

  @media only screen and (max-width: 736px) {
    .welcome-container--ftl {
      display: flex;
      flex-direction: column-reverse;
    }

    .teaser-image {
      padding: 0;
      float: none;
      width: 100%;
      align-self: center;
      max-height: 10em;
      object-fit: cover;
    }
  }
}
