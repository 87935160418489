.imgPerson {
  object-fit: cover;
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.contentMarginBottom {
  margin-bottom: 1rem;
}

.media {
  @include until($tablet) {
    flex-direction: column;
  }
}
