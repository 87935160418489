$texasInstruments: #CC0000;
$texasInstrumentsOpacity: rgba(204, 0, 0, 0.6);
$dark-gray: #363636;

.texasRedLink {
    color: $texasInstruments;
}

.underline {
    text-decoration: underline;
}

.padding-right {
    padding-right: 1rem;
}

.is-centred {
    display: flex;
    align-items: center;
    justify-content: center;
}

.countdown-boxes {
    padding: 0 1rem;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    overflow: hidden;
}
.days {
    background-color: $texasInstruments;
}
.hours {
    background-color: #DD1D33;
}
.mins {
    background-color: #EE3B67;
}
.secs {
    background-color: $dark-pink;
}

.live-icon {
    text-align: center;
    width: 3rem;
    margin-right: 1rem;
}

.padding-top {
    padding-top: 2rem;
}

.decreaseWidth {
    width: 45%;
}

.texasInstrumentsHover {
    &:hover, &:focus, &:active {
        color: $texasInstruments !important;
      }
}

.paddingLeft {
    padding-left: 1.5rem;
}

.sectionH1 {
    color: black;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
}

.registerNowButton {
    background-color: $texasInstruments !important;
    color: white !important;
    font-weight: bold;
    display: flex;
      &:hover {
        background-color: $texasInstrumentsOpacity !important;
        color: $dark-gray !important;
    }
}
