$color__primary: #01b0e5;
$color__primary-opacity: rgba(1, 176, 229, 0.6);

.navbar--flex-wrap {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.link-primary {
    color: $color__primary;
}

.link-primary--hover {
    &:hover, &:focus, &:active {
        color: $color__primary !important;
      }
}

.link-hover--red {
    &:hover, &:focus, &:active {
        color: red !important;
      }
}

.link-hover--orange {
    &:hover, &:focus, &:active {
        color: orange !important;
      }
}

.link-hover--yellow {
    &:hover, &:focus, &:active {
        color: gold !important;
      }
}

.link-hover--green {
    &:hover, &:focus, &:active {
        color: yellowgreen !important;
      }
}

.link-hover--purple {
    &:hover, &:focus, &:active {
        color: palevioletred !important;
      }
}

.link-hover--pink {
    &:hover, &:focus, &:active {
        color: $dark-pink !important;
      }
}

.image--banner {
    width: 80%;
    display: block;
    margin: 0 auto;

    @media only screen and (max-width: 736px) {
      width: 100%;
      max-height: 18em;
      object-fit: cover;
  }
}

.button--register-now {
    background-color: $color__primary !important;
    color: white !important;
    font-weight: bold;
    display: flex;
        &:hover {
        background-color: $color__primary-opacity !important;
        color: $dark-gray !important;
    }
}

.list--unordered ul {
    padding-left: 25px;
    list-style: disc;
}

.list--unordered b {
    margin-top: 0.5em
}

.list--ordered {
    padding-left: 1rem;
    margin-top: 1em;
}

.list--ordered ul {
    padding-left: 30px;
    list-style: circle;
}
