@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #7f00f9;
$purple-dark: #78125b;
$yellow: #fff55d;
$pink: #ec8c94;
$pink-light: #fad1ef;
$dark-pink: #ff589a;
$dark-pink-opacity:  rgba(255, 88, 154, 0.3);
$beige-light: #d0d1cd;
$beige-lighter: #fcfcfc;

// Update Bulma's global variables
$grey-light: $beige-light;
$primary: $pink;
$link: $pink;
$timeline-marker-border-size: 2px;
$timeline-line-width: 2px;
$family-sans-serif: 'Nunito', sans-serif;
$widescreen-enabled: false;
$fullhd-enabled: false;

$secondary:  $dark-pink-opacity;
$secondary-invert: findColorInvert($dark-pink-opacity) !default;

$texasInstruments: #CC0000;
$texasInstruments-invert: findColorInvert($texasInstruments) !default;

$custom-colors: (
    "secondary":($secondary, $secondary-invert),
    "texasInstruments": ($texasInstruments, $texasInstruments-invert),
    "outlined": ($secondary, white)
);

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$navbar-item-img-max-height: 3rem;
$navbar-height: 3rem;

@import "node_modules/bulma/bulma.sass";
@import "node_modules/bulma-timeline/src/sass/index.sass";

// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
@import "../node_modules/bulma/";
@import "../node_modules/bulma/sass/elements/button.sass";
//Components
@import "./pages/Home.scss";
@import "./components/Person.scss";
@import "./pages/TexasInstruments/TI.scss";
@import "./components/Header.scss";
@import "./pages/TexasInstruments/TIJudgingCriteria.scss";
@import "./pages/StartProud/main.scss";
